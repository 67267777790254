import {
  checkAccessToModule,
  hasPermission,
  hasPermissionInApp,
} from 'app/shared/utils';
import { User } from 'app/state/users';
import { Client, NEW_CLIENT_ROUTE } from 'app/state/clients';

import { constants } from '@config/constants';
import {
  checkAccessToClientEdit,
  NavigationConfigFunctions,
  NavigationSection,
  UserSectionNavigationName,
} from '@config';
import { environment } from '@environment';
import { checkExpressScenariosAccess, NavigationItemSource } from './common';

const { APPS_IDS, USER_PERMISSION_KEYS } = constants;

export const navigation: NavigationConfigFunctions = {
  dashboard: (user: User): NavigationSection => [
    {
      id: 'WM_HOME.HEADER.YOU',
      title: 'WM_HOME.HEADER.YOU',
      path: '/',
      hidden: checkAccessToModule(user, constants.MODULE_KEYS.YOU_V2),
    },
    {
      id: 'WM_HOME.HEADER.YOU',
      title: 'WM_HOME.HEADER.YOU',
      path: '/you/dashboard',
      hidden: !checkAccessToModule(user, constants.MODULE_KEYS.YOU_V2),
    },
    {
      id: 'WM_HOME.HEADER.APPS',
      title: 'WM_HOME.HEADER.APPS',
      path: '/apps/list',
      disabled: !checkAccessToModule(user, constants.MODULE_KEYS.APPS),
    },
  ],
  main: (countryCode: string, user: User): NavigationSection => [
    {
      id: 'Intelligence',
      title: 'Intelligence',
      nav: [
        {
          id: 'Fusion',
          title: 'Fusion',
          path: `https://fusionplatform.io/platform/insights/R06UT1xtfsCz31XFCrDm`,
          external: true,
          source: NavigationItemSource.LOREAL,
        },
        {
          id: 'Geograph',
          title: 'Geograph',
          source: NavigationItemSource.LOREAL,
          external: true,
          path: 'https://www.insightsshack.com/wmloreal_geosp_luxe/',
        },
        {
          id: 'Origami',
          title: 'Origami',
          path: `https://origami.wmplanningtools.com`,
          external: true,
          source: NavigationItemSource.WAVEMAKER,
        },
        {
          id: 'Full Funnel Allocation',
          title: 'Full Funnel Allocation',
          path: `https://tableau.insidemedia.net/#/site/Maxus_Demo/views/FullFunnelTest/BrandHealth?:iid=1`,
          external: true,
          source: NavigationItemSource.LOREAL,
        },
        {
          id: 'Audience Galaxy',
          title: 'Audience Galaxy',
          path: `/audience-galaxy`,
          source: NavigationItemSource.WAVEMAKER,
        },
      ],
    },
    {
      id: 'Planning',
      title: 'Planning',
      nav: [
        {
          id: 'Maximize',
          title: 'Maximize',
          source: NavigationItemSource.WAVEMAKER,
          nav: [
            {
              id: 'WM_HOME.NAVIGATION_MAIN.AUDIENCES',
              title: 'WM_HOME.NAVIGATION_MAIN.AUDIENCES',
              path: `/audiences/map`,
            },
            {
              id: 'Touchpoints Configuration',
              title: 'WM_HOME.NAVIGATION_MAIN.TOUCHPOINTS',
              path: `/touchpoints-ui`,
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.CAMPAIGNS',
              title: 'WM_HOME.NAVIGATION_MAIN.CAMPAIGNS',
              path: `/campaigns/list`,
            },
            {
              id: 'WM_HOME.NAVIGATION_MAIN.EXPRESS_SCENARIOS',
              title: 'WM_HOME.NAVIGATION_MAIN.EXPRESS_SCENARIOS',
              path: `/express-scenarios`,
              hidden: !checkExpressScenariosAccess(user),
            },
          ],
        },
        {
          id: 'Copilot',
          title: 'Copilot',
          path: `https://demo.copilot.ai/strategies`,
          external: true,
          source: NavigationItemSource.LOREAL,
        },
      ],
    },
    {
      id: 'Experience',
      title: 'Experience',
      nav: [
        {
          id: 'Create',
          title: 'Create',
          source: NavigationItemSource.LOREAL,
        },
      ],
    },
    {
      id: 'Operations',
      title: 'Operations',
      nav: [
        {
          id: 'Guardrail',
          title: 'Guardrail',
          source: NavigationItemSource.LOREAL,
        },
        {
          id: 'Baseline',
          title: 'Baseline',
          source: NavigationItemSource.LOREAL,
          external: true,
          path: 'https://baseline.mindshareapps.com/',
        },
      ],
    },
    {
      id: 'Governance',
      title: 'Governance',
      nav: [
        {
          id: 'IDx',
          title: 'IDx',
          path: `https://idx.groupm.tech/`,
          external: true,
          source: NavigationItemSource.LOREAL,
        },
        {
          id: 'Data Utilization Engine',
          title: 'Data Utilization Engine',
          path: `https://dev-data-accelerator.groupm.tech/loreal/`,
          external: true,
          source: NavigationItemSource.LOREAL,
        },
        {
          id: 'Data Ethics Compass',
          title: 'Data Ethics Compass',
          path: `https://dataethicscompass.groupm.tech/`,
          external: true,
          source: NavigationItemSource.LOREAL,
        },
        {
          id: 'Carbon Calculator',
          title: 'Carbon Calculator',
          path: `https://dev-ccs.groupm.tech/`,
          external: true,
          source: NavigationItemSource.LOREAL,
        },
        {
          id: 'Personal Data Navigator',
          title: 'Personal Data Navigator',
          path: `https://personal-data-navigator.groupm.tech/`,
          external: true,
          source: NavigationItemSource.LOREAL,
        },
      ],
    },
  ],
  avatarNavigation: (user: User): NavigationSection => [
    {
      id: 'WM_HOME.HEADER.ADMINISTRATION',
      title: 'WM_HOME.HEADER.ADMINISTRATION',
      nav: [
        {
          hidden: !hasPermissionInApp(user, APPS_IDS.WM_APPS, [
            USER_PERMISSION_KEYS.CAN_MANAGE_MARKET_APPS,
            USER_PERMISSION_KEYS.CAN_MANAGE_GLOBAL_APPS,
            USER_PERMISSION_KEYS.CAN_MANAGE_ALL_APPS,
          ]),
          id: 'WM_HOME.HEADER.APP_SETTING',
          title: 'WM_HOME.HEADER.APP_SETTING',
          path: '/apps/admin-panel',
        },
        {
          hidden: !hasPermission(user, USER_PERMISSION_KEYS.CAN_MANAGE_MARKET),
          id: 'WM_HOME.HEADER.MARKET',
          title: 'WM_HOME.HEADER.MARKET',
          path: '/market-admin/panel',
        },
        {
          hidden: !hasPermissionInApp(user, APPS_IDS.SCORECARDS, [
            USER_PERMISSION_KEYS.CAN_VIEW_SQM,
          ]),
          id: 'WM_HOME.HEADER.SCORECARDS',
          title: 'WM_HOME.HEADER.SCORECARDS',
          path: '/sqm/list',
        },
      ],
    },
    {
      id: 'WM_HOME.HEADER.USER',
      title: 'WM_HOME.HEADER.USER',
      nav: [
        {
          id: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
          title: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
          path: '/user/settings',
        },
        {
          id: 'WM_HOME.HEADER.LOGOUT',
          title: 'WM_HOME.HEADER.LOGOUT',
          path: `${environment.authAppUrl}/logout`,
        },
      ],
    },
  ],
  clientCreation: (client: Client, user: User): NavigationSection => {
    const clientId = (client && client.id) || NEW_CLIENT_ROUTE;
    const marketsDisabled = !client?.markets?.length;

    return [
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.NEW_ADVERTISER',
        title: 'WM_HOME.NAVIGATION_CLIENTS.NEW_ADVERTISER',
        path: `/clients/client-creation/new`,
        hidden: client?.isAccepted || clientId !== NEW_CLIENT_ROUTE,
      },
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.PENDING_REQUEST',
        title: 'WM_HOME.NAVIGATION_CLIENTS.PENDING_REQUEST',
        path: `/clients/client-creation/${clientId}/pending`,
        hidden: client?.isAccepted || clientId === NEW_CLIENT_ROUTE,
      },
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.ADVERTISER',
        title: 'WM_HOME.NAVIGATION_CLIENTS.ADVERTISER',
        path: `/clients/client-creation/${clientId}/client-management`,
        disabled: !checkAccessToClientEdit(user),
        hidden: !client?.isAccepted,
      },
      {
        id: 'WM_HOME.NAVIGATION_CLIENTS.MARKETS',
        title: 'WM_HOME.NAVIGATION_CLIENTS.MARKETS',
        path: `/clients/client-creation/${clientId}/markets-management`,
        hidden: !client?.isAccepted || marketsDisabled,
      },
    ];
  },
  userSection: () => [
    {
      name: UserSectionNavigationName.news,
      hidden: false,
      disabled: false,
    },
    {
      name: UserSectionNavigationName.help,
      hidden: false,
      disabled: false,
      children: [
        {
          name: UserSectionNavigationName.helpTrainingPortal,
          hidden: true,
          disabled: false,
        },
        {
          name: UserSectionNavigationName.helpProductTour,
          hidden: false,
          disabled: false,
        },
        {
          name: UserSectionNavigationName.helpSitemap,
          hidden: true,
          disabled: false,
        },
      ],
    },
  ],
  userSettings: (): NavigationSection => [
    {
      id: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
      title: 'WM_HOME.HEADER.ACCOUNT_SETTINGS',
      path: '/user/settings',
    },
  ],
};
