import { createAction, props } from '@ngrx/store';
import { Country, Myself } from '@mads/wm-ng-components';

import { Page, PagesReadersRelation } from 'src/app/interfaces';
import { LegacyHelpMenuInfo } from './contextual-help.utils';

export const setCurrentTag = createAction(
  '[ContextualHelp] set curent tag',
  props<{ currentTag: string | null }>()
);

export const setContextualHelpMenuInfo = createAction(
  '[ContextualHelp] set help menu info',
  props<{ data: LegacyHelpMenuInfo }>()
);

export const resetContextualHelpMenuInfo = createAction(
  '[ContextualHelp] reset help menu info'
);

export const setMatomoSection = createAction(
  '[ContextualHelp] set matomo section',
  props<{ section: string | null }>()
);

export const getPages = createAction(
  '[ContextualHelp] get pages',
  props<{
    tag: string,
    localContentSymbol: Country['code'],
    userLanguage: string,
    userId: Myself['id'],
  }>()
);
export const getPagesSuccess = createAction(
  '[ContextualHelp] get pages success',
  props<{ res: any, userLanguage: string }>()
);
export const getPagesFailure = createAction(
  '[ContextualHelp] get pages failure',
  props<{ error: any; }>()
);

export const markPageAsRead = createAction(
  '[ContextualHelp] mark page as read',
  props<{
    pageId: Page['id'],
    userId: Myself['id'],
  }>()
);
export const markPageAsReadSuccess = createAction(
  '[ContextualHelp] mark page as read success',
  props<{ res: PagesReadersRelation }>()
);
export const markPageAsReadFailure = createAction(
  '[ContextualHelp] mark page as read failure',
  props<{ error: any; }>()
);
