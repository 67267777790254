import { environment } from 'src/environments/environment';
import { ModeCodes } from '@mads/wm-ng-components';

export const constants = {
  APP_NAME: {
    WMOS: 'WMOS',
  },
  USER_PERMISSION_KEYS: {
    CAN_MANAGE_MARKET: 'CAN_MANAGE_MARKET',
    CAN_RECREATE_MARKET: 'CAN_RECREATE_MARKET',
    CAN_ACCESS: 'CAN_ACCESS',
    CAN_UI_ACCESS: 'CAN_UI_ACCESS',
    FEATURE_FLAGS_KEY: 'FEATURE_FLAGS',
    CAN_MANAGE_MARKET_APPS: 'CAN_MANAGE_MARKET_APPS',
    CAN_MANAGE_GLOBAL_APPS: 'CAN_MANAGE_GLOBAL_APPS',
    CAN_MANAGE_ALL_APPS: 'CAN_MANAGE_ALL_APPS',
    CAN_ADD_CLIENT: 'CAN_ADD_CLIENT',
    CAN_ADD_MARKET: 'CAN_ADD_MARKET',
    CAN_ADD_BRAND: 'CAN_ADD_BRAND',
    CAN_ACCESS_MODULE: 'CAN_ACCESS_MODULE',
    CAN_ACCESS_MODE: 'CAN_ACCESS_MODE',
    CAN_VIEW_SQM: 'CAN_VIEW_SCORECARDS_MANAGEMENT',
    SHOULD_SEE_QUESTIONNAIRE: 'CAN_SEE_QUESTIONNAIRE',
    OMIT_USER_EVENTS: 'EVENTS_WHITELISTING',
    SIMPLIFIED_MENU_ACCESS: 'SIMPLIFIED_MENU_ACCESS',
    CAN_ENTER_EXPRESS_SCENARIOS: 'MXMZ_JUNIOR_APP_ACCESS',
    CAN_ACCEPT_CLIENT: 'CAN_ACCEPT_CLIENT',
  },
  FEATURE_FLAG_KEYS: {
    CAN_ENTER_CAMPAIGNS_LIST: 'MXMZ_CAMPAIGNS_LIST_FF',
    FF_CAN_VIEW: 'FF_CAN_VIEW',
    CAN_SEE_B2B_SCORECARD: 'CAN_SEE_B2B_SCORECARD',
  },
  HEADER_TYPES: {
    HOME: 'home',
    BREADCRUMBS_WITHOUT_DRAWER: 'breadcrumbs-without-drawer',
    BREADCRUMBS_WITH_DRAWER: 'breadcrumbs-with-drawer',
    NONE: 'none',
  },
  MARKET_STATUS: {
    LIVE: 'live',
    INACTIVE: 'inactive',
    TEST: 'test',
    PITCH: 'pitch',
  },
  APPS_IDS: {
    WM_APPS: environment.appsIds.appsAppId,
    SCORECARDS: environment.appsIds.scorecardsAppId,
  },
  NEWS_LIMIT: 41,
  NEWS_FAILSAFE_IMAGES_FOLDER: '/assets/news-tile-failsafe-images/',
  MODULE_KEYS: {
    APPS: 'APPS',
    AUDIENCE_TRANSLATOR: 'AUDIENCE_TRANSLATOR',
    BUDGET: 'BUDGET',
    MAXIMIZE: 'MAXIMIZE',
    NEWS: 'NEWS',
    GROWTH_PATH: 'GROWTH_PATH',
    GEOGRAPH: 'GEOGRAPH',
    OPPORTUNITIES: 'OPPORTUNITIES',
    PROFILE: 'PROFILE',
    SCORECARDS: 'SCORECARDS',
    TRANSFORM: 'TRANSFORM',
    YOU_V2: 'YOU_V2',
    YOU_V1: 'YOU_V1',
    AUDIENCE_GALAXY: 'AUDIENCE_GALAXY',
    PROVOKE_BRIEF: 'PROVOKE_BRIEF',
    FOUR_MORES: 'FOUR_MORES',
    DATA_STRATEGY_DECK: 'DATA_STRATEGY_DECK',
    SITEMAP: 'SITEMAP',
    VELOCITY: 'VELOCITY',
    CONSUMER_EXPERIENCE_INSIGHTS: 'CONSUMER_EXPERIENCE_INSIGHTS',
    INFLUENCER_VIEWER: 'INFLUENCER_VIEWER',
    AI_CENTER: 'AI_CENTER',
  },
  YOU_V2_CONSTANTS: {
    LOCAL_STORAGE_ALLOW_YOU_V2_ANIMATION: 'ALLOW_YOU_V2_ANIMATION',
    ANIMATION_ROUTE: '/you/welcome-animation',
    DEFAULT_ROUTE: '/you/dashboard',
  },
  MATOMO_START_TRACKING_INFO: 'APPLICATION_STARTUP',
  SUPPORT_MAIL: 'os.support@wmglobal.com',
};

export const WAS_HELP_MENU_INFOTIP_DISMISSED = 'WAS_HELP_MENU_INFOTIP_DISMISSED';
export const TEST_ENVIRONMENT = 'TEST_ENVIRONMENT';

export const externalAgenciesModeCodes = [ModeCodes.MONDELEZ, ModeCodes.LOREAL];
