import { RouterEffects, RouterFacade } from './router';

import { clientsReducer, ClientsEffects, ClientsState, ClientsFacade } from './clients';
import { usersReducer, UsersEffects, UsersState, UsersFacade } from './users';
import { personalReducer, PersonalEffects, PersonalState, PersonalFacade } from './personal';
import { newsSidebarReducer, NewsSidebarEffects, NewsSidebarState, NewsSidebarFacade } from './news-sidebar';
import { BrandsFacade, BrandsEffects, BrandsState, brandsReducer } from './brands';
import { BreadcrumbsFacade, BreadcrumbsState, breadcrumbsReducer } from './breadcrumbs';
import { applicationsReducer } from './applications/applications.reducer';
import { ApplicationsEffects, ApplicationsState } from './applications';
import { ApplicationsFacade } from './applications/applications.facade';
import { ContextualHelpState, contextualHelpReducer, ContextualHelpFacade } from './contextual-help';
// uncomment for @questionnaire
import { questionnaireReducer } from './questionnaire/questionnaire.reducer';
import { QuestionnaireEffects } from './questionnaire/questionnaire.effects';
import { QuestionnaireFacade } from './questionnaire/questionnaire.facade';
import { questionnaireFeatureKey, QuestionnaireState } from './questionnaire/questionnaire.model';
// -----------------------------------
import { ProjectsEffects, ProjectsFacade, projectsReducer, ProjectsState } from './projects';

export interface IAction {
  type: string;
  payload?: any;
}

export const reducers = {
  brands: brandsReducer,
  clients: clientsReducer,
  users: usersReducer,
  newsSidebar: newsSidebarReducer,
  personal: personalReducer,
  breadcrumbs: breadcrumbsReducer,
  applications: applicationsReducer,
  contextualHelp: contextualHelpReducer,
  [questionnaireFeatureKey]: questionnaireReducer, // uncomment for @questionnaire
  projects: projectsReducer,
};

export const effects = [
  BrandsEffects,
  ClientsEffects,
  RouterEffects,
  UsersEffects,
  NewsSidebarEffects,
  PersonalEffects,
  ApplicationsEffects,
  QuestionnaireEffects, // uncomment for @questionnaire
  ProjectsEffects,
];

export const facades = [
  BrandsFacade,
  ClientsFacade,
  RouterFacade,
  UsersFacade,
  NewsSidebarFacade,
  PersonalFacade,
  BreadcrumbsFacade,
  ApplicationsFacade,
  ContextualHelpFacade,
  QuestionnaireFacade, // uncomment for @questionnaire
  ProjectsFacade,
];

export interface AppState {
  brands: BrandsState;
  clients: ClientsState;
  users: UsersState;
  personal: PersonalState;
  newsSidebar: NewsSidebarState;
  breadcrumbs: BreadcrumbsState;
  applications: ApplicationsState;
  contextualHelp: ContextualHelpState;
  [questionnaireFeatureKey]: QuestionnaireState;
  projects: ProjectsState;
}
