import { Brand, Client } from '@mads/wm-ng-components';

export interface Selection {
  brandId: Brand['id'];
  project: Project;
}

export interface ModeSettings {
  brandId: Brand['id'];
  modeOn: boolean;
  newestProjectCreation?: Project['createdAt']; // to allow newer projects to overwrite user settings
}

export enum PROCESS_TYPE {
  PITCH = 'PITCH',
  STRATEGY = 'STRATEGY',
  AUDIT = 'AUDIT',
  MEDIA_PLANNING = 'MEDIA_PLAN',
}


export interface TaskFile {
  fileName: string;
  url: string;
}

export enum TOOLS {
  SCORECARDS = 'SCORECARDS',
  OPPORTUNITIES = 'OPPORTUNITIES',
  DATA_STRATEGY = 'DATA_STRATEGY',
  AUDIENCES = 'AUDIENCES',
  TOUCHPOINTS = 'TOUCHPOINTS',
  CAMPAIGNS = 'CAMPAIGNS',
  IDEAS_TOOLKIT = 'IDEAS_TOOLKIT',
  INSIGHTS_TOOLKIT = 'INSIGHTS_TOOLKIT',
  PRECISION_TOOLKIT = 'PRECISION_TOOLKIT',
  AUDIENCE_GALAXY = 'AUDIENCE_GALAXY',
  STRATEGY_TOOLKIT = 'STRATEGY_TOOLKIT',
  BUDGET = 'BUDGET',
  SOURCE_OF_GROWTH = 'SOURCE_OF_GROWTH',
  PROVOKE_THE_BRIEF = 'PROVOKE_THE_BRIEF',
  APPS = 'APPS',
  OTHER = 'OTHER',
}

export const tools: {tool: TOOLS; translationKey: string; urlPattern: string; }[] = [
  { tool: TOOLS.APPS, translationKey: 'WM_HOME.HEADER.APPS', urlPattern: 'apps'},
  { tool: TOOLS.AUDIENCE_GALAXY, translationKey: 'WM_HOME.NAVIGATION_MAIN.AUDIENCE_GALAXY', urlPattern: 'audience-galaxy'},
  { tool: TOOLS.AUDIENCES, translationKey: 'WM_HOME.NAVIGATION_MAIN.AUDIENCES', urlPattern: 'audiences'},
  { tool: TOOLS.BUDGET, translationKey: 'WM_HOME.NAVIGATION_MAIN.BUDGET', urlPattern: 'budget'},
  { tool: TOOLS.CAMPAIGNS, translationKey: 'WM_HOME.NAVIGATION_MAIN.CAMPAIGNS_PARENT', urlPattern: 'campaigns'},
  { tool: TOOLS.DATA_STRATEGY, translationKey: 'WM_HOME.NAVIGATION_MAIN.DATA_STRATEGY_DECK', urlPattern: 'data-strategy-deck'},
  { tool: TOOLS.OPPORTUNITIES, translationKey: 'WM_HOME.NAVIGATION_MAIN.OPPORTUNITIES', urlPattern: 'growth-opportunities'},
  { tool: TOOLS.OTHER, translationKey: 'WM_HOME.NAVIGATION_MAIN.OTHER', urlPattern: 'other'},
  { tool: TOOLS.PROVOKE_THE_BRIEF, translationKey: 'WM_HOME.NAVIGATION_MAIN.PROVOKE_THE_BRIEF', urlPattern: 'provoke-brief'},
  { tool: TOOLS.SCORECARDS, translationKey: 'WM_HOME.NAVIGATION_MAIN.SCORECARDS', urlPattern: 'scorecards'},
  { tool: TOOLS.SOURCE_OF_GROWTH, translationKey: 'WM_HOME.NAVIGATION_MAIN.FOUR_MORES', urlPattern: 'sog'},
  { tool: TOOLS.TOUCHPOINTS, translationKey: 'WM_HOME.NAVIGATION_MAIN.TOUCHPOINTS', urlPattern: 'touchpoints'},
];

export interface TaskComment {
  urls?: TaskFile[];
  filesId: string[];
  text: string;
  id: string;
  createdAt: string;
  createdBy: string;
  editedAt: string;
}

export interface Task {
  name: string;
  link: string;
  source: TOOLS;
  deadline: string;
  priority: string;
  output: string;
  order: number;
  assignees: string[];
  status?: string;
  filesId?: string[];
  urls?: TaskFile[];
  comments: TaskComment[];
  id: string;
  createdAt: string;
  createdBy: string;
  editedAt: string;
  projectId?: Project['id']; // this is added only in header-wrapper-component, on the fly
}

export interface TasksColumn {
  name: string;
  description?: string;
  order: number;
  tasks: Task[];
  id: string;
  createdAt: string;
  createdBy: string;
  editedAt: string;
}

export interface Project {
  name: string;
  deadline: string;
  processType: PROCESS_TYPE;
  start: string;
  workspace: Client['id'];
  brand: Brand['id'][];
  templateId: string;
  columns: TasksColumn[];
  draft: boolean;
  id: string;
  createdAt: string;
  createdBy: string;
  editedAt: string;
}

// no magic strings!
export const nameOfPropertyByWhichWeSortProjectsToDecideWhichToChooseByDefault = 'createdAt';
export const nameOfPropertyByWhichWeSortColumnsAndTask = 'order';
export const maximumAmountOfColumnsInARowThatWeCanDisplay = 5;
export const brandMenuModeCodeForProjectBuilder = 'PROJECT_BUILDER'
