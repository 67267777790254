import { environment } from 'src/environments/environment';

export const APPS_NAMES_MAP = {
  [environment.appsIds.appId]: 'OS',
  [environment.appsIds.marketAdminAppId]: 'WM_HOME.HEADER.MARKET',
  [environment.appsIds.appsAppId]: 'WM_HOME.HEADER.APPS',
  [environment.appsIds.documentationProxyAppId]: 'WM_HOME.HEADER.HELP',
  [environment.appsIds.newsSettingsAppId]: 'WM_HOME.HEADER.NEWS',
  [environment.appsIds.audiencesAppId]: 'WM_HOME.NAVIGATION_MAIN.AUDIENCES',
  [environment.appsIds.budgetAppId]: 'WM_HOME.NAVIGATION_MAIN.BUDGET',
  [environment.appsIds.campaignsAppId]: 'WM_HOME.NAVIGATION_MAIN.CAMPAIGNS',
  [environment.appsIds.touchpointsAppId]: 'WM_HOME.NAVIGATION_MAIN.TOUCHPOINTS',
  [environment.appsIds.growthOpportunitiesAppId]: 'WM_HOME.NAVIGATION_MAIN.OPPORTUNITIES',
  [environment.appsIds.scorecardsAppId]: 'WM_HOME.NAVIGATION_MAIN.SCORECARDS',
  [environment.appsIds.cartAppId]: 'WM_HOME.NAVIGATION_MAIN.CART',
  [environment.appsIds.audienceGalaxyId]: 'WM_HOME.NAVIGATION_MAIN.AUDIENCE_GALAXY'
};
