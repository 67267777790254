import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { constants } from '@config/constants';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ClientsFacade } from 'app/state/clients';
import { filter, take } from 'rxjs';

@Component({
  selector: 'app-pending-client',
  templateUrl: './pending-client.component.html',
  styleUrls: ['./pending-client.component.scss'],
})
export class PendingClientComponent extends OnDestroyMixin {
  public client$ = this.clientsFacade.selectedClient$;
  public SUPPORT_MAIL = constants.SUPPORT_MAIL;

  constructor(
    private clientsFacade: ClientsFacade,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this._listenRouterEvents();
  }

  private _listenRouterEvents(): void {
    this.router.events
      .pipe(
        untilComponentDestroyed(this),
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe((_) => this.changeDetectorRef.detectChanges());
  }
}
