const DEFAULT_PAGE_TITLE = 'Wavemaker OS';
const DEFAULT_MATOMO_TITLE = 'WMOS>Uncategorized';
export const CONTEXTUAL_HELP_TITLE_PREFIX = 'WMOS>Contextual';
export const NEWS_DRAWER_MATOMO_TITLE = 'WMOS>News>Drawer';

export const titles = {
  DEFAULT: {
    PAGE_TITLE: DEFAULT_PAGE_TITLE,
    MATOMO_TITLE: DEFAULT_MATOMO_TITLE,
  },
  MODULES_LIST: {
    PAGE_TITLE: DEFAULT_PAGE_TITLE,
    MATOMO_TITLE: 'WMOS>Modules list',
  },
  MARKETS_LIST: {
    PAGE_TITLE: DEFAULT_PAGE_TITLE,
    MATOMO_TITLE: 'WMOS>Markets list',
  },
  CAMPAIGNS_UI: {
    PAGE_TITLE: 'OS: Campaigns List',
    MATOMO_TITLE: 'WMOS>You>Maximize>Campaigns List',
  },
  CAMPAIGNS_SETUP: {
    PAGE_TITLE: 'OS: Campaigns Setup',
    MATOMO_TITLE: 'WMOS>You>Maximize>Campaigns Setup',
  },
  CLIENT_CREATION: {
    PAGE_TITLE: DEFAULT_PAGE_TITLE,
    MATOMO_TITLE: 'WMOS>Client creation',
  },
  APPS: {
    PAGE_TITLE: 'OS: Apps',
    MATOMO_TITLE: 'WMOS>Apps',
  },
  AUDIENCE_GALAXY: {
    PAGE_TITLE: 'OS: Partner Audience Overlaps',
    MATOMO_TITLE: 'WMOS>You>Transform>Partner Audience Overlaps',
  },
  WM_STATS: {
    PAGE_TITLE: 'OS: WM Stats',
    MATOMO_TITLE: 'WMOS>You>WM Stats',
  },
  PRODUCTS_STATS: {
    PAGE_TITLE: 'OS: Products Stats',
    MATOMO_TITLE: 'WMOS>You>Products Stats',
  },
  GEOGRAPH: {
    PAGE_TITLE: 'OS: Geograph',
    MATOMO_TITLE: 'WMOS>You>Geograph',
  },
  AUDIENCES: {
    PAGE_TITLE: 'OS: Audiences',
    MATOMO_TITLE: 'WMOS>You>Maximize>Audiences',
  },
  BRIEFS: {
    PAGE_TITLE: 'OS: Campaigns',
    MATOMO_TITLE: 'WMOS>You>Maximize>Campaigns',
  },
  BUDGET: {
    PAGE_TITLE: 'OS: Budget',
    MATOMO_TITLE: 'WMOS>You>Unlock>Tools>Budget',
  },
  CART: {
    PAGE_TITLE: 'OS: Cart',
    MATOMO_TITLE: 'WMOS>You>Cart',
  },
  DOCUMENTATION: {
    PAGE_TITLE: 'OS: Documentation',
    MATOMO_TITLE: 'WMOS>Documentation',
  },
  GROWTH_OPPORTUNITIES: {
    PAGE_TITLE: 'OS: Opportunities',
    MATOMO_TITLE: 'WMOS>You>Unlock>Opportunities',
  },
  MARKET_ADMIN: {
    PAGE_TITLE: 'OS: Market Admin',
    MATOMO_TITLE: 'WMOS>You>Market Admin',
  },
  NEWS: {
    PAGE_TITLE: 'OS: News',
    MATOMO_TITLE: 'WMOS>News',
  },
  GROWTH_PATH: {
    PAGE_TITLE: 'OS: Project Builder',
    MATOMO_TITLE: 'WMOS>Project Builder',
  },
  PROVOKE_BRIEF: {
    PAGE_TITLE: 'OS: Provoke the brief',
    MATOMO_TITLE: 'WMOS>You>Transform>Provoke the brief',
  },
  SCORECARDS_QUESTION_MANAGEMENT: {
    PAGE_TITLE: 'OS: Scorecards questions management',
    MATOMO_TITLE: 'WMOS>You>Unlock>Scorecards questions management',
  },
  SCORECARDS: {
    PAGE_TITLE: 'OS: Scorecards',
    MATOMO_TITLE: 'WMOS>You>Unlock>Scorecards',
  },
  TOUCHPOINTS: {
    PAGE_TITLE: 'OS: Touchpoints',
    MATOMO_TITLE: 'WMOS>You>Maximize>Touchpoints',
  },
  YOU_NEW: {
    PAGE_TITLE: 'OS: Home',
    MATOMO_TITLE: 'WMOS>You',
  },
  FOUR_MORES: {
    PAGE_TITLE: 'OS: Source of growth',
    MATOMO_TITLE: 'WMOS>You>Transform>Strategy>Source of growth',
  },
  USER_SETTINGS: {
    PAGE_TITLE: 'OS: Settings',
    MATOMO_TITLE: 'WMOS>User>Settings',
  },
  MP_COMPARISON: {
    PAGE_TITLE: 'OS: Media Plans Comparison',
    MATOMO_TITLE: 'WMOS>You>Maximize>MP-Comparison',
  },
  MP_RESULTS: {
    PAGE_TITLE: 'OS: Media Plan Results',
    MATOMO_TITLE: 'WMOS>You>Maximize>MP-Results',
  },
  MP_UPLOADER: {
    PAGE_TITLE: 'OS: Custom Plan Uploader',
    MATOMO_TITLE: 'WMOS>You>Maximize>MP-Uploader',
  },
  EXPRESS_SCENARIOS: {
    PAGE_TITLE: 'OS: Express Scenarios',
    MATOMO_TITLE: 'WMOS>You>Maximize>Express Scenarios',
  },
  DATA_STRATEGY_DECK: {
    PAGE_TITLE: 'OS: Data Strategy',
    MATOMO_TITLE: 'WMOS>You>Unlock>Data Strategy',
  },
  SITEMAP: {
    PAGE_TITLE: 'OS: Sitemap',
    MATOMO_TITLE: 'WMOS>Sitemap',
  },
  VELOCITY: {
    PAGE_TITLE: 'OS: Long-term Growth Simulator',
    MATOMO_TITLE: 'WMOS>You>Transform>Long-term Growth Simulator',
  },
  CONSUMER_EXPERIENCE_INSIGHTS: {
    PAGE_TITLE: 'OS: Consumer Experience Insights',
    MATOMO_TITLE: 'WMOS>You>Transform>Insights>Consumer Experience Insights',
  },
  INFLUENCER_VIEWER: {
    PAGE_TITLE: 'OS: Influencers for Architect',
    MATOMO_TITLE: 'WMOS>You>Maximize>Tools>Influencers for Architect',
  },
  AI_CENTER: {
    PAGE_TITLE: 'OS: AI Center',
    MATOMO_TITLE: 'WMOS>AI Center',
  },
  CXI_AUDIENCES: {
    PAGE_TITLE: 'OS: Partner Audience Insights',
    MATOMO_TITLE: 'WMOS>You>Maximize>Investigate the audience>Partner Audience Insights',
  },
  AUDIENCE_TRANSLATOR: {
    PAGE_TITLE: 'OS: Audience Translator',
    MATOMO_TITLE: 'WMOS>Maximize>Audiences>Audience Translator',
  },
  CLIENT_REQUEST: {
    PAGE_TITLE: 'OS: Client request approval',
    MATOMO_TITLE: 'WMOS>Client Request Approval',
  },
};
